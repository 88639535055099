@import '../../../sass/variables';

.MainFooter {
  margin-top: 64px;
  width: 100%;
  padding: 16px;
  background-color: $secondary;
  color: $fontSecondary;
  display: flex;
  justify-content: center;
  align-items: center;
}