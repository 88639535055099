$bgColor: #faf9ff;
$bgContainer: #ffffff;
$primary: #1f59ee;
$primaryTrans: #1f5aee2a;
$secondary: #19181d;
$secondaryTrans: #19181da8;
$success: #22b943;
$fontPrimary: #19181d;
$fontSecondary: #ffffff;
$disable: #767c80;

$shadowBasic: 0px 16px 24px rgba(48, 137, 182, 0.06), 0px 2px 6px rgba(48, 137, 182, 0.04), 0px 0px 1px rgba(48, 137, 182, 0.04);
$shadowPrimary: 0px 16px 24px rgba(7, 133, 196, 0.06), 0px 2px 6px rgba(7, 133, 196, 0.04), 0px 0px 1px rgba(7, 133, 196, 0.04);
