@import '../../sass/variables';

.InfoPage {
  background-color: $bgContainer;
  width: 100%;
  border-radius: 24px;
  padding: 72px 36px;
  box-shadow: $shadowBasic;
  color: $fontPrimary;
  .container {
    display: flex;
    margin-bottom: 44px;
    .info-title {
      flex: 2;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1.14rem;
    }
    .info-body {
      flex: 6;
      text-align: justify;
      line-height: 1.52rem;
      .skill-container {
        flex: 1;
        padding: 0px 14px;
      }
      .link-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        padding: 8px;
        overflow: hidden;
        border-radius: 50%;
        font-size: 1.8rem;
        margin-right: 16px;
        margin-bottom: 16px;
        background-color: $fontPrimary;
        color: $fontSecondary;
        transition: .2s;
        &:hover {
          transform: translateY(-4px);
        }
      }
    }
    .body-flex {
      display: flex;
      flex-wrap: wrap;
    }
  }
  @media screen and (max-width: 700px) {
    .container {
      flex-direction: column;
      .info-title {
        margin-bottom: 12px;
      }
    }
  }
  @media screen and (max-width: 465px) {
    .container {
      .list-flex {
        flex-direction: column;
      }
    }
  }
}