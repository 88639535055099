@import '../../../sass/variables';

.MainCard {
  width: 300px;
  height: 300px;
  margin: 16px;
  background-color: $secondary;
  box-shadow: $shadowBasic;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  &:hover {
    img {
      transform: scale(1.1, 1.1);
    }
  }
  &:hover .main-card-title {
    display: flex;
  }
  img {
    transition: .3s ease-in;
    width: 100%;
    height: auto;
  }
  .main-card-title {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    background-color: $secondaryTrans;
    color: $fontSecondary;
    text-transform: uppercase;
  }
  @media screen and (max-width: 680px) {
    margin: 16px 0px;
  }
}