@import '../../sass/variables';

.OtherPage {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 128px 0px;
  h1 {
    color: $disable;
    text-align: center;
  }
}