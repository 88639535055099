@import '../../../sass/variables';

.MainNavbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .nav-link {
    color: $fontPrimary;
    .link {
      color: $fontPrimary;
      padding: 8px 16px;
      margin-right: 8px;
      border-radius: 24px;
      font-weight: bold;
      transition: .2s;
      text-align: center;
    }
    .active {
      background-color: $secondary;
      color: $fontSecondary;
    }
  }
  .nav-action {
    display: flex;
    .btn {
      padding: 8px;
      border-radius: 24px;
      width: 158px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 8px;
      transition: .2s ease-in;
      &:hover {
        filter: brightness(110%);
      }
      .icon {
        margin-right: 8px;
        color: $primary;
      }
      .label {
        color: $primary;
      }
    }
    .btn-download {
      background-color: $primary;
      .icon { 
        color: $fontSecondary;
      }
      .label {
        color: $fontSecondary;
      }
    }
    .btn-message {
      background-color: $success;
      .icon { 
        color: $fontSecondary;
      }
      .label {
        color: $fontSecondary;
      }
    }
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;
    .nav-link {
      order: 2;
    }
    .nav-action {
      order: 1;
      margin-bottom: 24px;
    }
  }
  @media screen and (max-width: 465px) {
    flex-direction: column;
    .nav-link {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .link {
        font-size: .8rem;
        width: 120px;
        padding: 10px 0px;
        margin: 0px 12px 8px 12px;
      }
    }
    .nav-action {
      .btn {
        width: 120px;
        .icon {
          font-size: .8rem;
        }
        .label {
          font-size: .8rem;
        }
      }
    }
  }
}