@import '../../../sass/variables';

.MainHeader {
  width: 100%;
  .head-cover {
    width: 100%;
    height: 220px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: auto;
    }
  }
  .head-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -75px;
    .ava {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      overflow: hidden;
      border: 8px solid $bgContainer;
      img {
        width: 100%;
        height: auto;
      }
    }
    .name {
      margin-bottom: 4px;
      font-size: 1.48rem;
    }
    .as {
      font-size: 1.2rem;
      color: $disable;
      font-weight: bold;
      text-align: center;
    }
  }
  @media screen and (max-width: 380px) {
    .head-cover {
      img {
        width: auto;
        height: 100%;
      }
    }
  }
}