@import '../../../sass/variables';

.MainModal {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: $primaryTrans;
  z-index: 5;
  .modal-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .modal-content {
      width: 80%;
      height: 720px;
      background-color: $bgContainer;
      border-radius: 8px;
      display: flex;
      z-index: 10;
      overflow: auto;
      .modal-header {
        width: 720px;
        height: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        position: relative;
        &:hover .modal-img-link {
          display: flex;
        }
        img {
          width: 100%;
          height: auto;
        }
        .modal-img-link {
          position: absolute;
          top: 0px;
          left: 0px;
          display: none;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          background-color: $secondaryTrans;
          color: $fontSecondary;
        }
        .modal-back {
          background-color: $bgColor;
          color: $fontPrimary;
          box-shadow: $shadowBasic;
          position: absolute;
          top: 24px;
          left: 24px;
          display: flex;
          width: 40px;
          height: 40px;
          justify-content: center;
          align-items: center;
          font-size: 1.2rem;
          border-radius: 4px;
          cursor: pointer;
          transition: .2s;
          &:hover {
            transform: scale(1.1, 1.1);
          }
        }
      }
      .modal-detail {
        flex: 1;
        padding: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .modal-title {
          font-size: 3rem;
          margin-bottom: 16px;
          text-transform: uppercase;
        }
        .modal-description {
          font-size: 1rem;
          margin-bottom: 12px;
          line-height: 1.48rem;
          text-align: justify;
        }
        .modal-role {
          font-weight: bold;
          margin: 4px 0px;
        }
      }
      @media screen and (max-width: 1340px) {
        height: 680px;
        .modal-header {
          width: 680px;
        }
      }
      @media screen and (max-width: 1230px) {
        display: block;
        .modal-header {
          width: 100%;
          height: 380px;
        }
      }
      @media screen and (max-width: 768px) {
        .modal-header {
          .modal-img-link {
            display: flex;
          }
        }
      }
      @media screen and (max-width: 435px) {
        border-radius: 0px;
        width: 100%;
        height: 100%;
        .modal-header {
          width: 100%;
          height: 280px;
        }
        .modal-detail {
          padding: 24px 8px;
        }
      }
    }
  }
  .modal-offset {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}