@import './sass/fonts';
@import './sass/variables';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: unset;
  outline: unset;
  text-decoration: unset;
}

body {
  background-color: $bgColor;
}

.App {
  .main-container {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    .content-container {
      width: 1080px;
      padding: 16px;
      .page-container {
        margin-top: 24px;
      }
    }
  }
  @media screen and (max-width: 465px) {
    .main-container {
      .content-container {
        padding: 0px;
        .page-container {
          margin-top: 16px;
        }
      }
    }
  }
}