@import '../../../sass/variables';

.UpButton {
  position: fixed;
  bottom: 32px;
  right: 32px;
  background-color: $primary;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: unset;
  outline: unset;
  cursor: pointer;
  transition: .2s;
  .icon {
    color: $fontSecondary;
    font-size: 1.2rem;
  }
  &:hover {
    transform: scale(1.1, 1.1);
  }

  @media screen and (max-width: 900px) {
    bottom: 16px;
    right: 16px;
  }
}